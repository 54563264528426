const text1 = `「実印」とは、市区町村に登録された印鑑のことです。
市区町村に印鑑登録をして受領すると、その印鑑が登録者のものであることが市区町村によって証明され、
申請すると「印鑑登録証明書」が交付されます。
頑張れ。 自治体に提出する印鑑を「実印」といいます。`;

const text2 = `「銀行印」とは、その名のとおり銀行や信用金庫といった金融機関に対して届出を行ない、
正式に登録された印鑑のことです。おもに預金口座を開設したり、
窓口で預金を引き出したりするとき、本人確認のために使用します。`;

const text3 = `「認印 みとめいんorにんいん 」とは、書類の内容に対して「確認しました」「了解しました」といった承認意思を示すために使う印鑑です。
特定の機関に向けて届出・登録を行なう必要はなく、現物さえあれば印鑑として機能します。
その分、法的効力はあまり強くないため、重要なシーンでは使えないことも多いです。`;

const text4 = `角印は通常の会社の印鑑として、日常的に使用します。
経費精算はもちろんですが、取引先に提出する請求書や領収書など、
また営業社員の作成したお知らせなどの一般的な書類にも使われています。`;

const text5 = ` 社判とは、企業で使用する法人印鑑全般のことを指します。
つまり、角印（社印）だけでなく、代表者印・
銀行印などの丸印※、スタンプなども含まれます。
上司から社判を押すことを指示された場合、角印なのかスタンプなのか、
どの社判を押すのか確認する必要があるでしょう。`;

module.exports = { text1, text2, text3, text4, text5 };
