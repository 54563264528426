/*const names = [
  "田中 秀範",
  "春日井 尚人",
  "筧 光能",
  "長谷川 良",
  "中川 幸作",
  "石川 満",
  "多門 康文",
  "米山 広之",
  "大久保 雄二",
  "柴田 侑",
  "山口 義治",
  "NGUYEN DINH TAI",
  "鈴木 康仁",
  "小原 晃生",
  "矢田 祥章",
  "丹羽 一朗",
  "今井 裕人",
  "籾山 陽平",
  "LE VAN LINH",
  "近藤 隼矢",
  "佐藤 俊孝",
  "小田 祐樹",
  "長谷部 大輝",
  "永井 雄大",
  "LOI QUANG DINH",
  "中嶋 奨",
  "神坂 紀代人",
  "関 裕介",
  "鈴木 翔太",
  "山根 正人",
  "片山 祐一",
  "松本 直人",
  "冨田 幸弘",
  "小久保 雄太",
  "伊藤 寛治",
  "小林 優人",
  "大塩 祐希",
  "山田 裕一",
  "小林 英嗣",
  "小島 慎平",
  "池田 雄亮",
  "庭野 竜彦",
  "中嶋 晴紀",
  "成田 峻",
  "政木 智行",
  "TRAN TIEN CAO",
  "Gokul Pant",
  "佐川 駿介",
  "松井 優",
  "岡 正樹",
  "伊藤 友",
  "江﨑 千紗",
  "加茂 寛子",
  "木島 加奈",
  "崔 艶麗",
  "宮田 里奈",
  "久保 晃奈",
  "杉江 陽子",
  "上坂 あい",
  "山口 香苗",
  "三井 文乃",
  "村澤 綾",
  "吉川 真佐美子",
  "石井 美玲子",
  "西脇 花恵",
  "菅野 有佐",
  "杉浦 桃香",
  "澤邊 香穂",
  "藤川 綾乃",
  "米田 純菜",
  "今井 柚佳里",
  "冨田 遥佳",
  "菅原 典子",
  "山下 志乃美",
  "小熊 千遥",
  "田中 麻奈美",
  "森 麻佑菜",
  "富永 希",
  "森 彩乃",
  "西尾 美央",
  "安藤 奈々",
  "西久保 雅子",
  "小島 佳奈子",
  "今田 麻友",
  "稗田 裕子",
  "福田 菜津子",
  "玉置 美保",
  "古山 岬",
  "二村 裕香乃",
  "一杉 美代",
  "中野 訓子",
  "長谷川 有紀",
  "中川 こころ",
  "西川 朱音",
  "林 沙彩",
  "一杉 歩未",
  "PHUNG THI NGA",
  "岡田 香菜子",
  "三輪 春那",
  "藤田 葵",
  "葛谷 秋美",
  "白濱 里美",
  "百合草 美里",
  "犬塚 ちはる",
  "安部 晴夏",
  "河村 里保",
  "藤原 早希",
  "本多 美穂",
  "黒石 美咲",
  "清水 智佳子",
  "林 紗羅",
  "伊奈 千尋",
  "福田 美波",
  "桑原 朱理",
  "伊藤 南美",
  "川上 結加",
  "倉知 莉沙",
  "田尻 直子",
  "土江 萌絵",
  "金原 仁美",
  "二宮 優希",
  "藤本 梨絵瑠",
  "川村 知紗",
  "神取 穂乃花",
  "野村 智香",
  "近藤 真央",
  "林 美穂",
  "是澤 明日香",
  "黒田 愛",
  "高橋 菜未",
  "松浦 目依",
  "小沢 来実",
  "清藤 未来",
  "依田 留奈",
  "渡邉 彩伽",
  "Ara Tabasum mansoor Ali",
  "チュー ティ リー",
  "チャン ティ ロアン",
  "ブォー ティ トゥイ",
  "八田 彩華",
  "石原 妙代",
  "平井 友理",
  "飯島 朋代",
  "小林 香織",
  "田﨑 未来",
  "足立 詩織",
  "加藤 理恵",
  "松田 日登美",
  "内田 恵美",
  "田中 慶子",
  "關 野乃花代",
  "いしいあんな",
  "原 久美子",
  "小林 千史",
  "口脇梨捺",
  "松永真帆",
  "米澤采莉",
  "大西未夏",
];

const mainbranch_name = [
  "春日井 尚人",
  "長谷川 良",
  "多門 康文",
  "大久保 雄二",
  "木島 加奈",
  "久保 晃奈",
  "山口 義治",
  "NGUYEN DINH TAI",
  "矢田 祥章",
  "今井 裕人",
  "村澤 綾",
  "LE VAN LINH",
  "西脇 花恵",
  "杉浦 桃香",
  "佐藤 俊孝",
  "澤邊 香穂",
  "長谷部 大輝",
  "森 麻佑菜",
  "富永 希",
  "西尾 美央",
  "安藤 奈々",
  "中嶋 奨",
  "神坂 紀代人",
  "今田 麻友",
  "二村 裕香乃",
  "松本 直人",
  "中川 こころ",
  "PHUNG THI NGA",
  "藤田 葵",
  "葛谷 秋美",
  "小久保 雄太",
  "犬塚 ちはる",
  "本多 美穂",
  "伊奈 千尋",
  "福田 美波",
  "桑原 朱理",
  "小林 英嗣",
  "川村 知紗",
  "林 美穂",
  "黒田 愛",
  "高橋 菜未",
  "依田 留奈",
  "田﨑 未来",
  "米澤 采莉",
  "DAO DAC QUOC",
  "田中 慶子",
  "小林 千史",
  "いしいあんな",
  "岡　正樹",
  "原　久美子",
  "社長",
  "石井美玲子",
  "中野順子",
  "金原仁美",
  "動画業者",
  "動画業者",
];
const chiryu_names = [
  "筧 光能",
  "宮田 里奈",
  "丹羽 一朗",
  "三井 文乃",
  "石井 美玲子",
  "田中 麻奈美",
  "永井 雄大",
  "小島 佳奈子",
  "玉置 美保",
  "中野 訓子",
  "長谷川 有紀",
  "片山 祐一",
  "林 沙彩",
  "岡田 香菜子",
  "三輪 春那",
  "伊藤 寛治",
  "黒石 美咲",
  "清水 智佳子",
  "伊藤 南美",
  "中嶋 晴紀",
  "野村 智香",
  "口脇 梨捺",
  "松永 真帆",
  "岡 正樹",
];

const tokoname_names = [
  "田中 秀範",
  "中川 幸作",
  "石川 満",
  "江﨑 千紗",
  "米山 広之",
  "加茂 寛子",
  "柴田 侑",
  "崔 艶麗",
  "宮田 里奈",
  "杉江 陽子",
  "鈴木 康仁",
  "小原 晃生",
  "籾山 陽平",
  "吉川 真佐美",
  "石井 美玲子",
  "近藤 隼矢",
  "菅野 有佐",
  "藤川 綾乃",
  "今井 柚佳里",
  "小田 祐樹",
  "冨田 遥佳",
  "菅原 典子",
  "山下 志乃美",
  "小熊 千遥",
  "LOI QUANG DINH",
  "森 彩乃",
  "西久保 雅子",
  "関 裕介",
  "稗田 裕子",
  "福田 菜津子",
  "鈴木 翔太",
  "古山 岬",
  "一杉 美代",
  "西川 朱音",
  "一杉 歩未",
  "白濱 里美",
  "百合草 美里",
  "河村 里保",
  "藤原 早希",
  "山田 裕一",
  "倉知 莉沙",
  "田尻 直子",
  "小島 慎平",
  "池田 雄亮",
  "金原 仁美",
  "藤本 梨絵瑠",
  "庭野 竜彦",
  "神取 穂乃花",
  "近藤 真央",
  "成田 峻",
  "政木 智行",
  "TRAN TIEN CAO",
  "小沢 来実",
  "渡邉 彩伽",
  "Gokul Pant",
  "Ara Tabasum mansoor Ali",
  "チュー ティ リー",
  "チャン ティ ロアン",
  "ブォー ティ トゥイ",
  "松井 優",
  "加藤 理恵",
  "松田 日登美",
  "内田 恵美",
  "TRINH HOANG LONG",
  "山本 里奈",
  "關 野乃花",
  "伊藤 友",
  "いしいあんな",
  "岡 正樹",
  "原 久美子",
  "平井さん",
];

const shimane_names = [
  "山根　正人",
  "安部　晴夏",
  "小林　優人",
  "林　紗羅",
  "川上　結加",
  "土江　萌絵",
  "是澤　明日香",
  "松浦　目依",
  "佐川　駿介",
  "石原　妙代",
  "平井　友理",
  "飯島　朋代",
  "小林　香織",
  "足立　詩織",
  "岩田　紋音",
  "坂根　雅人",
  "大西　未夏",
  "田中社長",
  "長谷川常務",
  "石井　美玲子",
];

const nagoya_names = [
  "玉置 美保",
  "三井 文乃",
  "籾山 陽平",
  "林 沙彩",
  "伊藤 南美",
  "内山 莉々菜",
]; 

const tokyo_names = [
  "冨田 幸弘",
  "澤邊 香穂",
  "二村 裕香乃",
  "犬塚 ちはる",
  "今田 麻友",
];*/

const branches = ["刈谷", "常滑", "知立", "島根", "東京", "名古屋", "博多"];
const documentTypes = [
    "契約書",
    "申請書",
    "申込書",
    "同意・確認書",
    "銀行書面",
    "その他",
];

const stamps = ["実印", "銀行印", "認印", "角印"];
const authorizerNames = [
    "田中秀範",
    "小川重彦",
    "長谷川良",
    "中川幸作",
    "柴田侑",
    "丹羽一朗",
    "今井裕人",
    "籾山陽平",
    "澤邊香穂",
    "山根正人",
    "中野訓子",
    "冨田幸弘",
    "江崎千紗",
];

const Personal_Seal_Authorizers = [
    "田中秀範",
    "小川重彦",
    "長谷川良",
    "中川幸作",
];
const Certified_Seal_Authorizers = [
    "田中秀範",
    "小川重彦",
    "長谷川良",
    "中川幸作",
];
const Bank_Seal_Authorizers = [
    "田中秀範",
    "小川重彦",
    "長谷川良",
    "中川幸作",
    "中野訓子",
];
const Administrator_Seal_Authorizers = [
    "田中秀範",
    "小川重彦",
    "長谷川良",
    "中川幸作",
    "柴田侑",
    "丹羽一朗",
    "今井裕人",
    "籾山陽平",
    "澤邊香穂",
    "山根正人",
    "中野訓子",
    "冨田幸弘",
    "江崎千紗",
];
const Square_Seal_Authorizers = [
    "田中秀範",
    "小川重彦",
    "長谷川良",
    "中川幸作",
    "柴田侑",
    "丹羽一朗",
    "今井裕人",
    "籾山陽平",
    "澤邊香穂",
    "山根正人",
    "中野訓子",
    "冨田幸弘",
    "江崎千紗",
    "清水智佳子",
    "片山祐一",
];

module.exports = {
    branches,
    documentTypes,
    stamps,
    authorizerNames,
    Personal_Seal_Authorizers,
    Certified_Seal_Authorizers,
    Bank_Seal_Authorizers,
    Administrator_Seal_Authorizers,
    Square_Seal_Authorizers,
};
